import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  count: null,

  isLoading: false,
};

export const historySlice = createSlice({
  name: "historyData",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    updateHistorySuccess: (state, action) => {
      state.isLoading = false;
      state.count = action.payload.length;

      state.data = action.payload;
    },

    hasError: (state, action) => {
      state.isLoading = false;
      state.count = null;

      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoading,
  updateHistorySuccess,

  hasError,
} = historySlice.actions;

export default historySlice.reducer;
