import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDWIXjv6aBfewk_xDVXIYC5LrcOHACeaWo",
  authDomain: "infinitybox-60a4d.firebaseapp.com",
  projectId: "infinitybox-60a4d",
  storageBucket: "infinitybox-60a4d.appspot.com",
  messagingSenderId: "1046016867605",
  appId: "1:1046016867605:web:47f36a096add19250dfd1c",
  measurementId: "G-GXXRYKFBCW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export const auth = getAuth(app);

export const STAGE_V2_API = process.env.REACT_APP_STAGE_API;
export const HOST_API = process.env.REACT_APP_OUTFLOW_API;
export const SCHEDULE_API = process.env.REACT_APP_SCHEDULE_API;
