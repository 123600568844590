import React, { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Paper,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Button,
  Typography,
  MenuItem,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SyncIcon from "@mui/icons-material/Sync";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  updateScheduleSuccess,
} from "../redux/slices/scheduleState";
import { scheduleDetails } from "../../api";
import { useSnackbar } from "notistack";
import { updateComputed } from "../redux/slices/unknownState";

const StatsFilter = () => {
  const times = ["9 to 12", "12 to 3", "3 to 6", "6 to 9"];

  const [date, setDate] = useState(
    localStorage.getItem("Date")
      ? localStorage.getItem("Date")
      : moment().format("YYYY-MM-DD")
  );

  const { change, value, refreshDb, compute } = useSelector(
    (state) => state.scheduleData
  );

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [slot, setSlot] = useState(
    localStorage.getItem("Slot") ? localStorage.getItem("Slot") : 1
  );

  const [timeSlot, setTimeSlot] = useState(
    localStorage.getItem("Time") ? localStorage.getItem("Time") : "9 to 12"
  );

  const [loading, setLoading] = useState(false);

  const handleTime = (event) => {
    if (event.target.value == "9 to 12") {
      setSlot(1);
      localStorage.setItem("Slot", 1);
    } else if (event.target.value == "12 to 3") {
      setSlot(2);
      localStorage.setItem("Slot", 2);
    } else if (event.target.value == "3 to 6") {
      setSlot(3);
      localStorage.setItem("Slot", 3);
    } else if (event.target.value == "6 to 9") {
      setSlot(4);
      localStorage.setItem("Slot", 4);
    }
    setTimeSlot(event.target.value);
    localStorage.setItem("Time", event.target.value);
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const details = await scheduleDetails(
        date,
        slot,
        change,
        false,
        true,
        false
      );
      if (details.data.status === "Success") {
        setLoading(false);
        enqueueSnackbar(details.data.message, { variant: "success" });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong.", { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      enqueueSnackbar("Something went wrong.", { variant: "error" });
    }
  };

  const handleReschedule = async () => {
    dispatch(startLoading());
    const details = await scheduleDetails(
      date,
      slot,
      change,
      false,
      false,
      true
    );
    dispatch(updateScheduleSuccess(details.data));
  };

  const fetchData = async () => {
    dispatch(startLoading());
    const details = await scheduleDetails(
      date,
      slot,
      change,
      value,
      refreshDb,
      compute
    );
    dispatch(updateScheduleSuccess(details.data));
  };

  const searchData = async () => {
    await fetchData();
    dispatch(updateComputed());
  };

  useEffect(() => {
    if (!localStorage.getItem("Date")) {
      localStorage.setItem("Date", date);
    }
    if (!localStorage.getItem("Slot")) {
      localStorage.setItem("Slot", slot);
    }
    if (!localStorage.getItem("Time")) {
      localStorage.setItem("Time", timeSlot);
    }

    fetchData();
  }, []);

  return (
    <div>
      <div
        style={{
          marginTop: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ marginLeft: "10px", padding: "1rem" }}>
          <div className="stats-bar">
            {/* <Box
              sx={{
                height: "30px",
                width: "150px",
                padding: "5px",
                display: "flex",
                justifyContent: "space-around",
                color: "#fff",
                fontSize: "30",
                backgroundColor: "red",
                alignItems: "center",
              }}
            >
              <strong>10</strong>
            </Box>
            <Box
              sx={{
                height: "30px",
                width: "150px",
                padding: "5px",
                display: "flex",
                justifyContent: "space-around",
                color: "#fff",
                fontSize: "30",
                marginLeft: "6rem",
                backgroundColor: "#42c415",
                alignItems: "center",
              }}
            >
              <strong>10</strong>
            </Box> */}
            <Button
              onClick={handleReschedule}
              sx={{ textDecoration: "none" }}
              variant="contained"
              startIcon={<CloudSyncIcon />}
            >
              <Typography sx={{ textDecoration: "none" }}>
                Show Rescheduled Orders
              </Typography>
            </Button>
            <Button
              onClick={handleUpdate}
              sx={{ textDecoration: "none", marginLeft: "1rem" }}
              variant="contained"
              startIcon={
                !loading ? (
                  <SyncIcon />
                ) : (
                  <CircularProgress color="inherit" size="1.2rem" />
                )
              }
            >
              <Typography sx={{ textDecoration: "none" }}>Update DB</Typography>
            </Button>
          </div>

          <Paper elevation={5} className="search-bar">
            <div className="filterline1">
              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  //   onKeyPress={handleKeypress}
                  label="Select Date"
                  inputFormat="dd/MM/yyyy"
                  value={date}
                  onChange={(newValue) => {
                    const today = moment(newValue).format("YYYY-MM-DD");

                    setDate(today);
                    localStorage.setItem("Date", today);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Divider
                className="divider"
                style={{ margin: 4 }}
                orientation="vertical"
              />

              <FormControl size={"small"} sx={{ minWidth: 200 }}>
                <InputLabel id="demo-simple-select-label">Time Slot</InputLabel>
                <Select
                  style={{ minWidth: 200, margin: 5 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={timeSlot}
                  label="Time Slot"
                  onChange={handleTime}
                >
                  {times.map((time) => (
                    <MenuItem value={time}>{time}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: 150,
                }}
              >
                <Button
                  className="searchButton"
                  style={{ margin: 5 }}
                  onClick={searchData}
                >
                  <SearchIcon className="searchButtonIcon" />
                </Button>
                <Typography style={{ fontSize: 11 }}>
                  Click here to search
                </Typography>
              </div>
            </div>
          </Paper>
        </Paper>
      </div>
    </div>
  );
};

export default StatsFilter;
