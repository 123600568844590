import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { assignCollectionAgent, scheduleHistory } from "../../api";
import {
  Paper,
  TextField,
  Box,
  Button,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  hasError,
  startLoading,
  updateHistorySuccess,
} from "../redux/slices/historyState";
import { useSnackbar } from "notistack";
import "../../styles/group-card.scss";
import "../../styles/schedule.scss";
import "../../styles/user-card.scss";
import OrderMapView from "./OrderMapView";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryAccordion from "./HistoryAccordion";
import { CopyToClipboard } from "react-copy-to-clipboard";

const HistoryListItem = (props) => {
  const [showMap, setShowMap] = useState(false);
  const [agent, setAgent] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  async function assignAgent(agent, value) {
    if (agent == "") {
      window.alert("Please select Collection agent");
      return;
    }

    let ids = [];

    value.map((order, index) => {
      ids.push(order.id);
    });

    var a = props.collectionAgent.filter((agents) => agents.username == agent);
    try {
      dispatch(startLoading());
      let assign = await assignCollectionAgent(a[0].id, ids);
      //   console.log("Agnt assigned", assign);
      if (assign.data.status === "Success") {
        enqueueSnackbar(assign.data.data.message, { variant: "success" });
        const history = await scheduleHistory(
          localStorage.getItem("historyDate"),
          localStorage.getItem("historySlot")
        );
        // console.log("HISTORY DATA", history);
        dispatch(updateHistorySuccess(history.data.data));
      }
    } catch (error) {
      enqueueSnackbar("Collection agent not assigned.", { variant: "error" });
      dispatch(hasError());
      console.log("ASSIGN ERROR", error);
    }
  }
  return (
    <Paper
      key={props.index}
      style={{
        padding: "20px",
        border: "2px solid ",
        borderColor: "green",
        marginBottom: "10px",
      }}
    >
      <div className="header-div">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",

            position: "sticky",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{ padding: "15px", fontWeight: "bold" }}
              variant="h6"
              component="div"
            >
              {`${props.dataKey} [${props.value.length}]`}
            </Typography>
            <CopyToClipboard
              text={props.value
                .map((order, index) => `${order.order_no}\n`)
                .join("")}
            >
              <Button
                sx={{
                  height: "2.4rem",
                  margin: "0.8rem",
                }}
                className="copy"
                startIcon={<ContentCopyIcon />}
              >
                Copy Order ID's
              </Button>
            </CopyToClipboard>
            <CopyToClipboard
              text={props.value
                .map((order, index) => `${index + 1}. ${order.order_no}\n`)
                .join("")}
            >
              <Button
                sx={{
                  height: "2.4rem",
                  margin: "0.7rem auto",
                }}
                className="copy"
                startIcon={<ContentCopyIcon />}
              >
                Copy ID's with Sl no
              </Button>
            </CopyToClipboard>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              options={props.collectionAgent}
              getOptionLabel={(agent) => agent.username}
              getOptionSelected={(option, value) =>
                option.username === value.username
              }
              sx={{ width: 180 }}
              renderInput={(params) => (
                <TextField {...params} label="Assign Agent" />
              )}
              onChange={(event, newValue) => {
                setAgent(newValue.username);
              }}
            />
            <Button
              style={{
                height: "2.4rem",
                margin: "0.4rem",

                textDecoration: "none",
              }}
              size="small"
              variant="contained"
              onClick={() => assignAgent(agent, props.value)}
            >
              Assign
            </Button>
          </div>
        </div>
      </div>

      <div
        id={`orderMap-${props.index}`}
        className={showMap ? "showMap" : "hideMap"}
      >
        {showMap ? <OrderMapView value={props.value} /> : null}
      </div>

      <Button
        key={`buttons-${props.index}`}
        onClick={() => setShowMap(!showMap)}
        style={{
          display: "flex",
          height: "2.4rem",
          width: "20%",
          margin: "1vh auto",
          alignItems: "center",
          textDecoration: "none",
        }}
        color="primary"
        variant="contained"
      >
        {showMap ? "Hide Map" : "Show Map"}
      </Button>

      {props.value.map((order, index) => (
        <HistoryAccordion
          order={order}
          index={index}
          collectionAgent={props.collectionAgent}
          agent={agent}
          setAgent={setAgent}
        />
      ))}
    </Paper>
  );
};

export default React.memo(HistoryListItem);
