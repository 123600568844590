import { Paper } from "@mui/material";
import { React } from "react";
import { useLocation } from "react-router-dom";
import alertImg from "../logo/alert.png";
import logoImg from "../logo/logo-black.png";
import "../styles/unauth.scss";

const FourZeroFour = (props) => {
  const Path = useLocation().search;
  const url = new URLSearchParams(Path).get("url");

  return (
    <Paper
      elevation={10}
      style={{ backgroundColor: "#ecffee", borderRadius: "1rem" }}
      className="unauth-card"
    >
      <div className="lg-header-comp">
        <div className="image-div">
          <img id="logo" src={logoImg} alt="INFINITY BOX"></img>
        </div>
      </div>

      <div
        style={{
          margin: "0.2rem auto",
          zIndex: "999",
        }}
      >
        <img
          style={{ height: "10rem", width: "10rem" }}
          id="alert"
          src={alertImg}
          alt="IB logo"
        ></img>
      </div>
      <p id="error">
        <code>HTTP ERROR : 404</code>
      </p>
      <p id="para-1">
        The page you are trying to access does not exist -
        <code id="cd">{url}</code>
      </p>
      <p id="para-2">
        If you think this is an error, please report the Backend team of
        Infinity Box!
      </p>
    </Paper>
  );
};

export default FourZeroFour;
