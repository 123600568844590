import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignCollectionAgent, scheduleDetails } from "../../api";
import {
  hasError,
  startLoading,
  updateScheduleSuccess,
} from "../redux/slices/scheduleState";
import {
  Paper,
  Grid,
  TextField,
  Box,
  Button,
  Autocomplete,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "../../styles/group-card.scss";
import "../../styles/schedule.scss";
import "../../styles/user-card.scss";
import ScheduleAccordion from "./ScheduleAccordion";
import OrderMapView from "./OrderMapView";
import ScheduleOrderList from "./ScheduleOrderList";
import ScheduleLink from "./ScheduleLink";
import { updateComputed } from "../redux/slices/unknownState";

const ScheduleOrderPanel = (props) => {
  const [agent, setAgent] = useState([]);

  const [showMap, setShowMap] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { value, refreshDb, compute } = useSelector(
    (state) => state.scheduleData
  );

  async function assignAgent(agent, orders, idx) {
    if (agent == "") {
      window.alert("Please select Collection agent");
      return;
    }
    // console.log("WHen clicked ", orders, idx);
    let ids = [];

    orders.orderList.map((order, index) => {
      ids.push(order.id);
    });

    var a = props.collectionAgent.filter((agents) => agents.username == agent);
    // // console.log(a[0].id);
    // console.log(ids, a[0].id);

    try {
      dispatch(startLoading());
      let assign = await assignCollectionAgent(a[0].id, ids);
      //   // console.log("Agnt assigned", assign);
      if (assign.data.status == "Success") {
        enqueueSnackbar(assign.data.data.message, { variant: "success" });
        const details = await scheduleDetails(
          localStorage.getItem("Date"),
          localStorage.getItem("Slot"),
          [],
          value,
          refreshDb,
          compute
        );
        dispatch(updateScheduleSuccess(details.data));
        dispatch(updateComputed());
      } else {
        dispatch(hasError());
        enqueueSnackbar("Collection agent not assigned.", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Collection agent not assigned.", { variant: "error" });
      dispatch(hasError());
      // console.log("ASSIGN ERROR", error);
    }
  }
  return (
    <div>
      <>
        {props.orderValue.Route == props.tabValue.route && (
          <div
            style={{
              padding: "15px",
              border: "2px solid ",
              borderColor: "green",
              marginBottom: "10px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                <Grid container item spacing={3}>
                  <Grid item xs={8}>
                    <Paper
                      key={props.orderIndex}
                      className="group-card"
                      sx={{
                        padding: "2rem",

                        marginRight: "auto",
                      }}
                    >
                      <div className="header-div">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <strong>Route: {props.orderValue.Route}</strong>
                            <h3 id="timeslot">{`${props.orderKey} [${props.orderValue.orderList.length} Orders]`}</h3>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Autocomplete
                              id="combo-box-demo"
                              options={props.collectionAgent}
                              getOptionLabel={(agent) => agent.username}
                              getOptionSelected={(option, value) =>
                                option.username === value.username
                              }
                              sx={{
                                width: 200,
                                margin: "1vh",
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Assign Agent" />
                              )}
                              onChange={(event, newValue) => {
                                setAgent(newValue.username);
                              }}
                            />
                            <Button
                              style={{
                                height: "2.4rem",
                                margin: "1rem auto",

                                textDecoration: "none",
                              }}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                assignAgent(
                                  agent,
                                  props.orderValue,
                                  props.orderIndex
                                )
                              }
                            >
                              Assign
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div
                        id={`orderMap_${props.orderIndex}`}
                        className={showMap ? "showMap" : "hideMap"}
                      >
                        {showMap ? (
                          <OrderMapView value={props.orderValue.orderList} />
                        ) : null}
                      </div>

                      <Button
                        onClick={() => setShowMap(!showMap)}
                        style={{
                          height: "2.4rem",
                          width: "20%",
                          margin: "1vh auto",

                          textDecoration: "none",
                        }}
                        color="primary"
                        variant="contained"
                      >
                        {showMap ? "Hide Map" : "Show Map"}
                      </Button>

                      {props.orderValue.orderList.map((cell, index) => {
                        return (
                          <ScheduleAccordion
                            cell={cell}
                            index={index}
                            collectionAgent={props.collectionAgent}
                            agent={agent}
                            setAgent={setAgent}
                          />
                        );
                      })}
                      {/* <ScheduleOrderList orderValue={props.orderValue} /> */}
                      <ScheduleLink orderValue={props.orderValue} />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
      </>
    </div>
  );
};

export default React.memo(ScheduleOrderPanel);
