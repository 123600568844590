import React, { useEffect } from "react";
import "../../styles/schedule.scss";

import { Routes, Route, Navigate } from "react-router-dom";
import History from "./History";
import Schedule from "./Schedule";
import Login from "./../Login";
import FourZeroFour from "./../404";
import Unauthenticated from "./../Unauthenticated";
import { useAuthDispatch, useAuthState } from "./../../context/context";
import ProtectedRoute from "./../ProtectedRoute";
import Reschedule from "./Reschedule";
import { isValidToken, setSession } from "../jwt";
import { logout } from "../../context";
import ChangeStatus from "./ChangeStatus";

function Body() {
  const userDetails = useAuthState();

  const dispatch = useAuthDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(dispatch, accessToken);
        } else {
          accessToken !== null && window.alert("Please login again.");
          logout(dispatch);
        }
      } catch (err) {
        console.error(err);
      }
    };

    initialize();
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/unauth" element={<Unauthenticated />} />

        <Route
          element={<ProtectedRoute userDetails={userDetails} isAdmin={true} />}
        >
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/history" element={<History />} />
          <Route path="/reschedule" element={<Reschedule />} />
          <Route path="/change" element={<ChangeStatus />} />
        </Route>

        <Route path="/" element={<Navigate replace to="/schedule" />} />
        <Route
          path="*"
          element={
            <Navigate replace to={`/404?url=${window.location.pathname}`} />
          }
        />
        <Route path="/404" element={<FourZeroFour />} />
      </Routes>
    </div>
  );
}

export default Body;
