import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";

const initialState = {
  data: [],
  error: null,
  isLoading: false,

  orderSummary: null,
  value: false,
  refreshDb: false,
  compute: false,
};

export const scheduleSlice = createSlice({
  name: "scheduleData",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    updateScheduleSuccess: (state, action) => {
      state.isLoading = false;

      state.data = action.payload.schedule;
      state.orderSummary = action.payload.summary;
    },

    hasError: (state, action) => {
      state.isLoading = false;

      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoading,
  updateScheduleSuccess,
  updateAssignSuccess,
  hasError,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
