import { configureStore } from "@reduxjs/toolkit";
import scheduleDataReducer from "./slices/scheduleState";
import rescheduleDataReducer from "./slices/rescheduleState";
import historyDataReducer from "./slices/historyState";
import unknownDataReducer from "./slices/unknownState";

export const store = configureStore({
  reducer: {
    scheduleData: scheduleDataReducer,
    rescheduleData: rescheduleDataReducer,
    historyData: historyDataReducer,
    unknownData: unknownDataReducer,
  },
});

const { dispatch } = store;

export { dispatch };
