import React, { useState } from "react";
import { Button, AppBar, Toolbar, Box, IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../logo/logo-black.png";
import "../styles/header.scss";
import { logout, useAuthDispatch, useAuthState } from "./../context";

const headerOptions2 = [
  {
    name: "Schedule",
    href: "/schedule",
  },
  {
    name: "History",
    href: "/history",
  },
  {
    name: "Re-schedule Orders",
    href: "/reschedule",
  },
  {
    name: "Change Status",
    href: "/change",
  },
];

const Header = () => {
  const user = useAuthState();
  const dispatch = useAuthDispatch();
  let navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = (e) => {
    logout(dispatch);
    navigate("/login", { replace: true });
  };

  return (
    <div>
      <div className="header-component">
        <AppBar position="fixed" sx={{ backgroundColor: "#c8facd" }}>
          <Toolbar>
            {user.loggedIn === true && user.username === "app-admin" ? (
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="success"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {headerOptions2.map((entry) => (
                    <Link style={{ textDecoration: "none" }} to={entry.href}>
                      <Button
                        style={{
                          height: "2.75rem",
                          margin: "0.85rem",
                          borderRadius: "0.75rem",
                          textDecoration: "none",
                          backgroundColor: "#9ae1a2",
                          color: "black",
                          fontWeight: "bold",
                        }}
                        variant="contained"
                        id="navbar-link"
                        key={entry.href}
                      >
                        {entry.name}
                      </Button>
                    </Link>
                  ))}
                </Menu>
              </Box>
            ) : (
              ""
            )}
            <div style={{ flexGrow: 1 }}>
              <div className="logo">
                <img src={logoImg} alt="INFINITY BOX"></img>
              </div>
            </div>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {user.loggedIn === true && user.username === "app-admin"
                ? headerOptions2.map((entry) => (
                    <Link style={{ textDecoration: "none" }} to={entry.href}>
                      <Button
                        style={{
                          height: "2.75rem",
                          margin: "0.85rem",
                          borderRadius: "0.75rem",
                          textDecoration: "none",
                          backgroundColor: "#9ae1a2",
                          color: "black",
                          fontWeight: "bold",
                        }}
                        variant="contained"
                        id="navbar-link"
                        key={entry.href}
                      >
                        {entry.name}
                      </Button>
                    </Link>
                  ))
                : ""}
            </Box>

            {user.token !== "" && (
              <Button
                style={{
                  height: "2.75rem",

                  borderRadius: "0.75rem",
                  textDecoration: "none",
                }}
                variant="contained"
                color="success"
                onClick={handleLogout}
              >
                Logout
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default Header;
