import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";
// import { dispatch } from "../store";
// import { dispatch } from "../store";

const initialState = {
  data: [],
  error: null,

  isLoading: false,
};

export const rescheduleSlice = createSlice({
  name: "rescheduleData",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    updateRescheduleSuccess: (state, action) => {
      state.isLoading = false;

      state.data = action.payload;
    },

    hasError: (state, action) => {
      state.isLoading = false;

      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoading,
  updateRescheduleSuccess,

  hasError,
} = rescheduleSlice.actions;

export default rescheduleSlice.reducer;
