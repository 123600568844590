import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ScheduleLink = (props) => {
  const [copied, setCopied] = useState(false);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ height: "1rem" }}
          //   onClick={isUserLoaded ? false : loadUserData}
        >
          <h3
            style={{
              fontWeight: "400",
            }}
            id="order-id"
          >
            {props.orderValue.travellingLink ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <span>
                    <i>Travelling Link:</i>
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CopyToClipboard
                text={
                  props.orderValue.travellingLink
                    ? props.orderValue.travellingLink
                    : ""
                }
                onCopy={() => setCopied(true)}
              >
                <Button className="copy" startIcon={<ContentCopyIcon />}>
                  Copy
                </Button>
              </CopyToClipboard>
            </div>
            {props.orderValue.travellingLink && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <h3
                  style={{
                    margin: 0,
                    width: "40%",
                    fontWeight: "500",
                  }}
                  id="address"
                >
                  Link:
                </h3>
                <a
                  href={props.orderValue.travellingLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: 0,
                    maxWidth: "60%",
                    wordWrap: "break-word",
                    fontWeight: "500",
                  }}
                  id="address"
                >
                  {props.orderValue.travellingLink}
                </a>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(ScheduleLink);
