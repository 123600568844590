import React from "react";
import { useSelector } from "react-redux";
import { Paper, Box, Typography, Grid, CircularProgress } from "@mui/material";

const RescheduleOutput = () => {
  const { isLoading, data } = useSelector((state) => state.rescheduleData);

  return (
    <div>
      {!isLoading ? (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data.length !== 0 && (
            <>
              <h3 align="center">Changed Rows: {data.changedRows}</h3>

              {data.incorrectOrderStatus.length !== 0 && (
                <Paper elevation={5} sx={{ width: 500, minHeight: 300 }}>
                  <Box sx={{ marginTop: "1rem", padding: "2rem", mb: 3 }}>
                    <Typography
                      sx={{ fontWeight: "bolder" }}
                      gutterBottom
                      variant="h5"
                      component="div"
                    >
                      Incorrect Order Status:
                    </Typography>

                    {data.incorrectOrderStatus.map((order, index) => (
                      <Grid
                        container
                        spacing={1}
                        sx={{ marginTop: "10px", border: 0.5, padding: "1rem" }}
                      >
                        <Typography sx={{ fontSize: "20px" }}>
                          Order Number: {order.order_no}
                        </Typography>
                        <Typography sx={{ fontSize: "20px" }}>
                          Order Status: <strong>{order.order_status}</strong>
                        </Typography>
                      </Grid>
                    ))}
                  </Box>
                </Paper>
              )}
            </>
          )}
        </div>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            value={100}
            size={56}
            thickness={4}
            sx={{ position: "absolute", top: "65%", left: "50%" }}
            disableShrink
          />
        </Box>
      )}
    </div>
  );
};

export default RescheduleOutput;
