import React from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

const OrderMapView = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.React_App_GOOGLE_MAPS_API_KEY,
  });

  const mapStyles = {
    maxWidth: "45vw",
    height: "250px",
    // position: "absolute",
    // left: "35%",
    margin: "2vh",
  };

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          zoom={13}
          mapContainerStyle={mapStyles}
          center={{
            lat: parseFloat(props.value[0].latitude),
            lng: parseFloat(props.value[0].longitude),
          }}
        >
          {props.value.map((cell, index) => {
            return (
              <MarkerF
                label={`${index + 1}`}
                key={index}
                id={index}
                position={{
                  lat: cell.latitude,
                  lng: cell.longitude,
                }}
              />
            );
          })}
        </GoogleMap>
      ) : null}
    </div>
  );
};

export default React.memo(OrderMapView);
