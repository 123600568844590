import React, { useState } from "react";
import {
  Paper,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Button,
  Typography,
  MenuItem,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Box,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import "../../styles/schedule.scss";
import "../../styles/change-route.scss";
import { useSnackbar } from "notistack";
import { updateStatus } from "../../api";

const ChangeStatus = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [summary, setSummary] = useState(null);
  const [slot, setSlot] = useState("");
  const [order, setOrder] = useState([]);
  const [text, setText] = useState("");

  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };

  const statusCustomer = "STATUS_WITH_CUSTOMER";
  const statusAgent = "STATUS_WITH_COLLECTION_AGENT";
  const statuses = ["With Customer", "With Collection Agent"];

  const handleTime = (event) => {
    setSlot(event.target.value);
  };

  const handleOrder = (event) => {
    setText(event.target.value);

    // let temp = event.target.value;

    // orderList = temp.split(" ");
    setOrder(event.target.value.split(" "));
  };

  const updateData = async () => {
    if (!order.length) {
      enqueueSnackbar("Enter Order ID", { variant: "error" });
      return;
    }

    if (slot === "") {
      enqueueSnackbar("Enter Status to change", { variant: "error" });
      return;
    }

    setLoading(true);

    try {
      let statusUpdate = await updateStatus(
        order,
        slot === "With Customer" ? statusCustomer : statusAgent
      );
      if (statusUpdate.status === "Success") {
        setSummary(statusUpdate.data);
        enqueueSnackbar("Order status update request successful.", {
          variant: "success",
        });
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(statusUpdate.message, { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wrong.", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <div>
      <div className="parent">
        <Paper elevation={5} className="search-bar">
          <div className="filterline1">
            <div style={{ maxWidth: "15rem", margin: 3 }}>
              <TextField
                size={"small"}
                labelId="demo-simple-select-label"
                style={{
                  width: "15rem",
                }}
                label="Order ID"
                fullWidth
                value={text}
                onChange={handleOrder}
                placeholder="Enter Order ID"
              />
            </div>
            <Divider
              className="divider"
              style={{ margin: 5 }}
              orientation="vertical"
            />
            <FormControl size={"small"} sx={{ minWidth: 220 }}>
              <InputLabel id="demo-simple-select-label">Mark as</InputLabel>
              <Select
                style={{ minWidth: 100, margin: 5 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={slot}
                label="Mark as"
                onChange={handleTime}
              >
                {statuses.map((status) => (
                  <MenuItem value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider
              className="divider"
              style={{ margin: 5 }}
              orientation="vertical"
            />
            <div className="update-button">
              <Button
                className="searchButton"
                style={{ margin: 5 }}
                onClick={updateData}
              >
                {!loading ? (
                  <IosShareIcon />
                ) : (
                  <CircularProgress color="inherit" size="1.2rem" />
                )}
              </Button>
              <Typography style={{ fontSize: 11 }}>Update</Typography>
            </div>
          </div>
        </Paper>
      </div>
      {order.length > 0 && (
        <Paper className="display-orders" elevation={5}>
          <strong>Orders Entered: {order.length}</strong>
          <br />
          <Grid
            container
            spacing={1}
            sx={{
              marginTop: "10px",
            }}
          >
            {order.map((o) => (
              <Box className="order-box" m={1} p={1}>
                {o}
              </Box>
            ))}
          </Grid>
        </Paper>
      )}

      {summary !== null && (
        <div className="parent">
          <Paper elevation={5} sx={{ padding: "10px" }}>
            <List sx={style} component="nav">
              <ListItem button>
                <ListItemText>
                  Status Changed: <strong>{summary.changedRows}</strong>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button>
                <ListItemText>
                  Status Not Updated: <strong>{summary.notUpdated}</strong>
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default ChangeStatus;
