let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).user.username
  : "";
let token = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).access_token
  : "";

export const initialState = {
  loggedIn: false || token !== "",
  username: "" || user,
  token: "" || token,
  loading: false,
  errorMessage: null,
};

// reducer
export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
        loggedIn: false,
        token: "",
        username: "",
        errorMessage: null,
      };

    case "LOGIN_SUCCESS":
      localStorage.setItem("accessToken", action.payload.access_token);
      return {
        ...initialState,
        username: action.payload.user.username,
        token: action.payload.access_token,
        loading: false,
        loggedIn: true,
        errorMessage: null,
      };

    case "LOGOUT":
      return {
        ...initialState,
        username: "",
        token: "",
        loggedIn: false,
        loading: false,
        errorMessage: null,
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
        loggedIn: false,
        username: "",
        token: "",
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
