import Header from "./components/Header";
import Body from "./components/Body/Body";
import Footer from "./components/Footer";
import "./styles/App.scss";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <div className="app-component">
        <Header />
        <Body />
        <Footer />
      </div>
    </SnackbarProvider>
  );
}

export default App;
