import { Paper, Button } from "@mui/material";
import { React } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import alertImg from "../logo/alert.png";
import logoImg from "../logo/logo-black.png";
import "../styles/unauth.scss";

const Unauthenticated = (props) => {
  let navigate = useNavigate();

  return (
    <Paper
      elevation={10}
      style={{
        backgroundColor: "#ecffee",
        borderRadius: "1rem",
        marginTop: "6vh",
      }}
      className="unauth-card"
    >
      <div className="lg-header-comp">
        <div className="image-div">
          <img id="logo" src={logoImg} alt="INFINITY BOX"></img>
        </div>
      </div>

      <div
        style={{
          margin: "0.2rem auto",
          zIndex: "999",
        }}
      >
        <img
          style={{ height: "10rem", width: "10rem" }}
          id="alert"
          src={alertImg}
          alt="IB LOGO"
        ></img>
      </div>
      <p id="error">
        <code>HTTP ERROR : 401</code>
      </p>
      <p id="para-1">
        You are <b> not authenticated</b> to access this page.
      </p>
      <p id="para-2">
        Please login:
        <Button
          className="login"
          variant="contained"
          sx={{ marginLeft: "1vh" }}
          onClick={(e) => {
            navigate("/login");
          }}
        >
          Login
        </Button>
      </p>
    </Paper>
  );
};

export default Unauthenticated;
