import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignCollectionAgent, scheduleDetails } from "../../api";
import {
  hasError,
  startLoading,
  updateScheduleSuccess,
} from "../redux/slices/scheduleState";
import {
  Paper,
  TextField,
  Button,
  IconButton,
  Divider,
  Autocomplete,
  CircularProgress,
  Chip,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "../../styles/group-card.scss";
import "../../styles/schedule.scss";
import "../../styles/user-card.scss";
import { useSnackbar } from "notistack";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { updateComputed } from "../redux/slices/unknownState";

const ScheduleAccordion = ({
  cell,
  index,
  collectionAgent,
  agent,
  setAgent,
}) => {
  const { isLoading, data, value, refreshDb, compute } = useSelector(
    (state) => state.scheduleData
  );

  const { unknownRoute, unknownLoading } = useSelector(
    (state) => state.unknownData
  );

  const [copied, setCopied] = useState(false);

  const groups = data && data.map((orders) => Object.keys(orders));

  const [route, setRoute] = useState([]);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  async function assignIndiOrder(agent, cell, idx) {
    if (agent == "") {
      window.alert("Please select Collection agent");
      return;
    }
    // console.log("WHen clicked ", agent, cell);

    var a = collectionAgent.filter((agents) => agents.username == agent);
    // console.log(a[0].id);
    // console.log(cell.id, a[0].id);

    try {
      dispatch(startLoading());
      let assign = await assignCollectionAgent(a[0].id, cell.id);
      //   // console.log("Agnt assigned", assign);
      if (assign.data.status === "Success") {
        enqueueSnackbar(assign.data.data.message, { variant: "success" });
        const details = await scheduleDetails(
          localStorage.getItem("Date"),
          localStorage.getItem("Slot"),
          [],
          value,
          refreshDb,
          compute
        );
        dispatch(updateScheduleSuccess(details.data));
        dispatch(updateComputed());
      } else {
        dispatch(hasError());
        enqueueSnackbar("Collection agent not assigned.", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Collection agent not assigned.", { variant: "error" });
      dispatch(hasError());
      // console.log("ASSIGN ERROR", error);
    }
  }

  async function changeRoute(cell) {
    // // console.log("CELL", cell);
    let change = [];
    let obj = {};
    obj[`${cell.order_no}`] = route;
    // // console.log("OBJ", obj);
    change.push(obj);

    try {
      dispatch(startLoading());
      let move = await scheduleDetails(
        localStorage.getItem("Date"),
        localStorage.getItem("Slot"),
        change,
        true,
        refreshDb,
        compute
      );
      //   // console.log("MOVED", move);
      dispatch(updateScheduleSuccess(move.data));
      dispatch(updateComputed());
    } catch (error) {
      enqueueSnackbar("Not moved.", { variant: "error" });
      dispatch(hasError());
      // console.log("ASSIGN ERROR", error);
    }
  }
  return (
    <>
      {!isLoading && (
        <Paper key={index} className="user-card" variant="outlined">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ height: "1rem" }}
              //   onClick={isUserLoaded ? false : loadUserData}
            >
              <h3
                style={{
                  fontWeight: "400",
                }}
                id="order-id"
              >
                {cell.order_no ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <span>
                        {index + 1}. Order ID: {cell.order_no}
                      </span>
                      <div style={{ marginLeft: "1vh" }}>
                        {cell.opted_for_bag === 1 ? (
                          <ShoppingBagIcon color="success" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ marginLeft: "1vh" }}>
                        {!unknownLoading ? (
                          unknownRoute.length !== 0 &&
                          unknownRoute.map((unknown, idx) =>
                            unknown.order_no === cell.order_no ? (
                              <Chip
                                label={`suggested: ${unknown.route} (${unknown.locality})`}
                                color="primary"
                                variant="outlined"
                                size="small"
                              />
                            ) : (
                              ""
                            )
                          )
                        ) : (
                          <CircularProgress color="inherit" size="1.2rem" />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </h3>
            </AccordionSummary>

            <AccordionDetails>
              <div
                style={{
                  padding: "15px",
                  border: "2px solid ",
                  borderColor: "green",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {cell.address && (
                      <>
                        <Autocomplete
                          size={"medium"}
                          id="combo-box-demo"
                          options={groups[0]}
                          getOptionLabel={(item) => item}
                          getOptionSelected={(option, value) =>
                            option === value
                          }
                          sx={{
                            marginLeft: "3vh",
                            width: 190,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Change Route To" />
                          )}
                          onChange={(event, newValue) => {
                            setRoute(newValue);
                          }}
                        />
                        <IconButton
                          style={{ marginLeft: "10px" }}
                          aria-label="submit"
                          size="large"
                          color="success"
                          onClick={() => changeRoute(cell)}
                        >
                          <CheckCircleIcon fontSize="inherit" />
                        </IconButton>
                        <Divider
                          variant="middle"
                          flexItem
                          style={{ margin: 6 }}
                          orientation="vertical"
                        />

                        <Autocomplete
                          size={"medium"}
                          id="combo-box-demo"
                          options={collectionAgent}
                          getOptionLabel={(agent) => agent.username}
                          getOptionSelected={(option, value) =>
                            option.username === value.username
                          }
                          sx={{
                            marginLeft: "3vh",
                            width: 190,
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Assign Order To" />
                          )}
                          onChange={(event, newValue) => {
                            setAgent(newValue.username);
                          }}
                        />
                        <Button
                          style={{
                            height: "2.5rem",
                            margin: "0.85rem",

                            textDecoration: "none",
                          }}
                          size="small"
                          variant="contained"
                          onClick={() => assignIndiOrder(agent, cell, index)}
                        >
                          Assign
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CopyToClipboard
                    text={
                      cell.address
                        ? `Order No: ${cell.order_no}\nQuantity: ${cell.quantity}\nName: ${cell.customer_name}\nPhone: ${cell.customer_phone}\nAddress: ${cell.address}\nMaps: ${cell.maps}`
                        : cell.travellingLink
                        ? `Travelling Link: ${cell.travellingLink}`
                        : cell.orderList
                            .map((orders, index) =>
                              Object.values(orders).map(
                                (row) =>
                                  `${index + 1}.\nOrder No: ${
                                    row[0].order_no
                                  }\nQuantity: ${row[1].quantity}\nName: ${
                                    row[2].customer_name
                                  }\nPhone: ${
                                    row[3].customer_phone
                                  }\nAddress: ${row[4].address}\nMaps: ${
                                    row[5].maps
                                  }\n\n`
                              )
                            )
                            .join("")
                    }
                    onCopy={() => setCopied(true)}
                  >
                    <Button className="copy" startIcon={<ContentCopyIcon />}>
                      {cell.orderList ? "Copy All" : "Copy"}
                    </Button>
                  </CopyToClipboard>
                </div>

                {cell.order_no && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        width: "40%",
                        fontWeight: "500",
                      }}
                      id="orderId"
                    >
                      Order ID:
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        width: "60%",
                        fontWeight: "500",
                      }}
                      id="orderId"
                    >
                      {cell.order_no}
                    </h3>
                  </div>
                )}

                {cell.quantity && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        width: "40%",
                        fontWeight: "500",
                      }}
                      id="quantity"
                    >
                      Quantity:
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        width: "60%",
                        fontWeight: "500",
                      }}
                      id="quantity"
                    >
                      {cell.quantity}
                    </h3>
                  </div>
                )}

                {cell.customer_name && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        width: "40%",
                        fontWeight: "500",
                      }}
                      id="name"
                    >
                      Name:
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        width: "60%",
                        fontWeight: "500",
                      }}
                      id="name"
                    >
                      {cell.customer_name}
                    </h3>
                  </div>
                )}

                {cell.customer_phone && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        width: "40%",
                        fontWeight: "500",
                      }}
                      id="phone"
                    >
                      Phone:
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        width: "60%",
                        fontWeight: "500",
                      }}
                      id="phone"
                    >
                      {cell.customer_phone}
                    </h3>
                  </div>
                )}

                {cell.address && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        width: "40%",
                        fontWeight: "500",
                      }}
                      id="address"
                    >
                      Address:
                    </h3>
                    <h3
                      style={{
                        margin: 0,
                        width: "60%",
                        fontWeight: "500",
                      }}
                      id="address"
                    >
                      {cell.address}
                    </h3>
                  </div>
                )}

                {cell.maps && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "3px",
                      marginBottom: "3px",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        width: "40%",
                        fontWeight: "500",
                      }}
                      id="maps"
                    >
                      Maps:
                    </h3>
                    <a
                      href={cell.maps}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        margin: 0,
                        width: "60%",
                        wordWrap: "break-word",
                        fontWeight: "500",
                      }}
                      id="maps"
                    >
                      {cell.maps}
                    </a>
                  </div>
                )}

                {cell.travellingLink && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        width: "40%",
                        fontWeight: "500",
                      }}
                      id="address"
                    >
                      Link:
                    </h3>
                    <a
                      href={cell.travellingLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        margin: 0,
                        maxWidth: "60%",
                        wordWrap: "break-word",
                        fontWeight: "500",
                      }}
                      id="address"
                    >
                      {cell.travellingLink}
                    </a>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </>
  );
};

export default React.memo(ScheduleAccordion);
