import { Navigate, Outlet } from "react-router-dom";

// ProtectedRoute.propTypes = {
//   children: PropTypes.node,
// };

export default function ProtectedRoute({ userDetails, isAdmin }) {
  //   return !userDetails.loggedIn ? (
  //     <Navigate to="/login" replace />
  //   ) : isAdmin && userDetails.username !== "app-admin" ? (
  //     <Navigate to="/unauth" replace />
  //   ) : (
  //     children
  //   );

  return !userDetails.loggedIn ? <Navigate to="/login" replace /> : <Outlet />;
}
