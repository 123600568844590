import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Box, CircularProgress, Button } from "@mui/material";

import "../../styles/group-card.scss";
import "../../styles/schedule.scss";
import "../../styles/user-card.scss";
import { collectionAgentType } from "../../api";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HistoryListItem from "./HistoryListItem";

const HistoryList = () => {
  const [collectionAgent, setCollectionAgent] = useState([]);

  const { isLoading, data, count } = useSelector((state) => state.historyData);

  useEffect(() => {
    const fetchCollection = async () => {
      let agents = await collectionAgentType();

      setCollectionAgent(agents);
    };

    fetchCollection();
  }, []);

  const result =
    data.length !== 0 &&
    data.reduce(
      (h, cell) =>
        Object.assign(h, {
          [cell.username]: (h[cell.username] || []).concat({
            order_no: cell.order_no,
            id: cell.id,
            quantity: cell.quantity,
            name: cell.customer_name,
            phone: cell.customer_phone,
            bag: cell.opted_for_bag,
            latitude: cell.latitude,
            longitude: cell.longitude,
            address: cell.address,
            maps: `https://www.google.com/maps/place/${cell.latitude},+${cell.longitude}`,
          }),
        }),
      {}
    );

  // console.log("DATA RESULT", result);

  // console.log("GROUPED RESULT", Object.entries(result));

  return (
    <div>
      {!isLoading ? (
        <div>
          {count !== null && (
            <div>
              <h3 align="center">
                Total Orders: {count}{" "}
                <CopyToClipboard
                  text={
                    data && data.map((order) => `${order.order_no}\n`).join("")
                  }
                >
                  <Button
                    variant="contained"
                    sx={{ marginLeft: "1vh", marginBottom: "1vh" }}
                    className="copy"
                    startIcon={<ContentCopyIcon />}
                  >
                    Copy All Order ID's
                  </Button>
                </CopyToClipboard>
              </h3>
            </div>
          )}
          <Box
            sx={{
              flexGrow: 1,
              display: "block",

              marginLeft: "auto",
              marginRight: "auto",
              width: "80%",
            }}
          >
            {result &&
              Object.entries(result).map(([key, value], index) => (
                <HistoryListItem
                  dataKey={key}
                  index={index}
                  value={value}
                  collectionAgent={collectionAgent}
                />
              ))}
          </Box>
        </div>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            value={100}
            size={56}
            thickness={4}
            sx={{ position: "absolute", top: "50%", left: "50%" }}
            disableShrink
          />
        </Box>
      )}
    </div>
  );
};

export default HistoryList;
