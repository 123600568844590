import axios from "./axios";

const { axiosInstance, axiosInstanceV2, axiosInstanceV3 } = axios;

export async function scheduleDetails(
  date,
  slot,
  change,
  value,
  refreshDb,
  compute
) {
  try {
    let data = {
      updateRoute: {
        value: value,
        data: change,
      },
      refreshDb: refreshDb,
      computeAssignedOrders: compute,
      date: date,
      slot: slot,
    };

    const response = await axiosInstance.post("/orders/scheduleRoute", data);

    return response;
    //   dispatch(
    //     scheduleSlice.actions.updateScheduleSuccess(response.data.schedule)
    //   );
  } catch (error) {
    //   dispatch(scheduleSlice.actions.hasError(error));
  }
}

export async function reschedOrders(orderList, schedule, checked) {
  try {
    let data = {
      orders: orderList,
      scheduled_at: schedule,
      markForScheduling: checked,
    };

    const response = await axiosInstance.post(
      "/orders/updateOrderDetails",
      data
    );
    // console.log("resched response", response);
    return response;
  } catch (error) {
    console.log("Error while resched orders", error);
  }
}

export async function scheduleHistory(date, slot) {
  try {
    const response = await axiosInstance.post("/orders/schedulingHistory", {
      scheduleDate: date,
      slot: slot,
    });
    // console.log("resched response", response);
    return response;
  } catch (error) {
    console.log("Error while history orders", error);
  }
}

export const collectionAgentType = async () => {
  try {
    let response = await axiosInstance.get(`/collection-agent/all-agents`);
    return response.data;
  } catch (error) {
    console.log("Error while calling all-agents api", error);
  }
};

export async function assignCollectionAgent(agent, orders) {
  try {
    let data = {
      collection_agent: agent,
      orders: orders,
    };
    const response = await axiosInstance.post(
      "/orders/assign-collection-agent",
      data
    );
    // console.log("collection response", response);
    return response;
    //   dispatch(
    //     scheduleSlice.actions.updateScheduleSuccess(response.data.schedule)
    //   );
  } catch (error) {
    //   dispatch(scheduleSlice.actions.hasError(error));
  }
}

export const authLogin = async (username, password) => {
  try {
    // console.log("data:", data.username);
    // let username = data.username;
    // let password = data.password;
    const res = await axiosInstanceV2.post("/auth/login", {
      username,
      password,
    });

    return res.data;
  } catch (error) {
    console.log("Error while auth login", error);
  }
};

export const postAccessToken = async (username, password) => {
  try {
    // console.log("data:", data.username);
    // let username = data.username;
    // let password = data.password;

    let response = await axiosInstance.post(`oauth/access_token`, {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    console.log("Error while fetching access token", error);
  }
};

export const mismatchOrders = async () => {
  try {
    let response = await axiosInstance.get(`/orders/timeMismatchOrders`);
    return response.data;
  } catch (error) {
    console.log("Error while calling mismatch orders api", error);
  }
};

export const unknownOrders = async (unknownArray) => {
  try {
    let response = await axiosInstanceV3.post(
      `/api-schedule-process/route-locality`,
      {
        orders: unknownArray,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling unknown routes api", error);
  }
};

export const updateStatus = async (order, status) => {
  try {
    let response = await axiosInstance.post(`/orders/resetOrderStatus`, {
      orders: order,
      status: status,
    });
    return response.data;
  } catch (error) {
    console.log("Error while updating order status", error);
  }
};
