import React, { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Paper,
  FormControl,
  InputLabel,
  TextField,
  Select,
  Button,
  Typography,
  MenuItem,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  updateHistorySuccess,
} from "../redux/slices/historyState";
import { scheduleHistory } from "../../api";

const HistoryFilter = () => {
  const [date, setDate] = useState(
    localStorage.getItem("historyDate")
      ? localStorage.getItem("historyDate")
      : moment().format("YYYY-MM-DD")
  );

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const times = ["1", "2", "3", "4"];

  const [slot, setSlot] = useState(
    localStorage.getItem("historySlot")
      ? localStorage.getItem("historySlot")
      : 1
  );

  const handleTime = (event) => {
    setSlot(event.target.value);
    localStorage.setItem("historySlot", event.target.value);
  };

  const searchData = async () => {
    setLoading(true);

    dispatch(startLoading());
    const history = await scheduleHistory(date, slot);
    // console.log("HISTORY DATA", history);

    dispatch(updateHistorySuccess(history.data.data));

    setLoading(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("historyDate")) {
      localStorage.setItem("historyDate", date);
    }
    if (!localStorage.getItem("historySlot")) {
      localStorage.setItem("historySlot", slot);
    }
  }, []);

  return (
    <div>
      <div
        style={{
          margin: "2vh auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{ width: 450, padding: "10px" }}
          elevation={5}
          className="search-bar"
        >
          <Typography sx={{ marginTop: "1vh", fontWeight: "bolder" }}>
            Search for Orders
          </Typography>
          <div className="historyline">
            <LocalizationProvider
              className="from-date"
              dateAdapter={AdapterDateFns}
            >
              <DesktopDatePicker
                //   onKeyPress={handleKeypress}
                label="Select Date"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(newValue) => {
                  const today = moment(newValue).format("YYYY-MM-DD");

                  setDate(today);
                  localStorage.setItem("historyDate", today);
                }}
                renderInput={(params) => (
                  <TextField size={"small"} {...params} />
                )}
              />
            </LocalizationProvider>
            <Divider
              className="divider"
              style={{ margin: 4 }}
              orientation="vertical"
            />
            <FormControl size={"small"} sx={{ minWidth: 100 }}>
              <InputLabel id="demo-simple-select-label">Slot</InputLabel>
              <Select
                style={{ minWidth: 100, margin: 5 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={slot}
                label="Slot"
                onChange={handleTime}
              >
                {times.map((time) => (
                  <MenuItem value={time}>{time}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider
              className="divider"
              style={{ margin: 4 }}
              orientation="vertical"
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 150,
              }}
            >
              <Button
                className="searchButton"
                style={{ margin: 5 }}
                onClick={searchData}
              >
                <SearchIcon className="searchButtonIcon" />
              </Button>
              <Typography style={{ fontSize: 11 }}>
                Click here to search
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default HistoryFilter;
