import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Paper,
  Button,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../styles/group-card.scss";
import "../../styles/schedule.scss";
import "../../styles/user-card.scss";
import { assignCollectionAgent, scheduleHistory } from "../../api";
import { useSnackbar } from "notistack";
import {
  hasError,
  startLoading,
  updateHistorySuccess,
} from "../redux/slices/historyState";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const HistoryAccordion = ({
  order,
  index,
  collectionAgent,
  agent,
  setAgent,
}) => {
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  async function assignIndiOrder(agent, order, idx) {
    if (agent == "") {
      window.alert("Please select Collection agent");
      return;
    }
    // console.log("WHen clicked ", agent, order);

    var a = collectionAgent.filter((agents) => agents.username == agent);
    // console.log(a[0].id);
    // console.log(order.id, a[0].id);

    try {
      dispatch(startLoading());
      let assign = await assignCollectionAgent(a[0].id, order.id);
      //   // console.log("Agnt assigned", assign);
      if (assign.data.status === "Success") {
        enqueueSnackbar(assign.data.data.message, { variant: "success" });
        const history = await scheduleHistory(
          localStorage.getItem("historyDate"),
          localStorage.getItem("historySlot")
        );
        // console.log("HISTORY DATA", history);
        dispatch(updateHistorySuccess(history.data.data));
      } else {
        dispatch(hasError());
        enqueueSnackbar("Collection agent not assigned.", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Collection agent not assigned.", { variant: "error" });
      dispatch(hasError());
      // console.log("ASSIGN ERROR", error);
    }
  }

  return (
    <div>
      <Paper key={index} className="user-card" variant="outlined">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ height: "1rem" }}
            //   onClick={isUserLoaded ? false : loadUserData}
          >
            <h3
              style={{
                fontWeight: "400",
              }}
              id="order-id"
            >
              {order.order_no && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <span>
                      {index + 1}. Order ID: {order.order_no}
                    </span>
                    <div style={{ marginLeft: "1vh" }}>
                      {order.bag === 1 ? (
                        <ShoppingBagIcon color="success" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                padding: "15px",
                border: "2px solid ",
                borderColor: "green",
              }}
            >
              <div
                style={{
                  display: "flex",

                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {order.address && (
                    <>
                      <Autocomplete
                        id="combo-box-demo"
                        options={collectionAgent}
                        getOptionLabel={(agent) => agent.username}
                        getOptionSelected={(option, value) =>
                          option.username === value.username
                        }
                        sx={{
                          marginLeft: "3vh",
                          width: 190,
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Assign Order To" />
                        )}
                        onChange={(event, newValue) => {
                          setAgent(newValue.username);
                        }}
                      />
                      <Button
                        style={{
                          height: "2.5rem",
                          margin: "0.85rem",

                          textDecoration: "none",
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => assignIndiOrder(agent, order, index)}
                      >
                        Assign
                      </Button>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CopyToClipboard
                    text={
                      order.address &&
                      `Order No: ${order.order_no}\nQuantity: ${order.quantity}\nName: ${order.name}\nPhone: ${order.phone}\nAddress: ${order.address}\nMaps: ${order.maps}`
                    }
                    onCopy={() => setCopied(true)}
                  >
                    <Button className="copy" startIcon={<ContentCopyIcon />}>
                      Copy
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>

              {order.order_no && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "3px",
                    marginBottom: "3px",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      width: "40%",
                      fontWeight: "500",
                    }}
                    id="orderId"
                  >
                    Order ID:
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      width: "60%",
                      fontWeight: "500",
                    }}
                    id="orderId"
                  >
                    {order.order_no}
                  </h3>
                </div>
              )}

              {order.quantity && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "3px",
                    marginBottom: "3px",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      width: "40%",
                      fontWeight: "500",
                    }}
                    id="quantity"
                  >
                    Quantity:
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      width: "60%",
                      fontWeight: "500",
                    }}
                    id="quantity"
                  >
                    {order.quantity}
                  </h3>
                </div>
              )}

              {order.name && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "3px",
                    marginBottom: "3px",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      width: "40%",
                      fontWeight: "500",
                    }}
                    id="name"
                  >
                    Name:
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      width: "60%",
                      fontWeight: "500",
                    }}
                    id="name"
                  >
                    {order.name}
                  </h3>
                </div>
              )}

              {order.phone && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "3px",
                    marginBottom: "3px",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      width: "40%",
                      fontWeight: "500",
                    }}
                    id="phone"
                  >
                    Phone:
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      width: "60%",
                      fontWeight: "500",
                    }}
                    id="phone"
                  >
                    {order.phone}
                  </h3>
                </div>
              )}

              {order.address && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      width: "40%",
                      fontWeight: "500",
                    }}
                    id="address"
                  >
                    Address:
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      width: "60%",
                      fontWeight: "500",
                    }}
                    id="address"
                  >
                    {order.address}
                  </h3>
                </div>
              )}

              {order.maps && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "3px",
                    marginBottom: "3px",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      width: "40%",
                      fontWeight: "500",
                    }}
                    id="maps"
                  >
                    Maps:
                  </h3>
                  <a
                    href={order.maps}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      margin: 0,
                      width: "60%",
                      wordWrap: "break-word",
                      fontWeight: "500",
                    }}
                    id="maps"
                  >
                    {order.maps}
                  </a>
                </div>
              )}

              {/* {order.travellingLink && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection:
                          "row",
                      }}
                    >
                      <h3
                        style={{
                          margin: 0,
                          width: "40%",
                          fontWeight: "500",
                        }}
                        id="address"
                      >
                        Link:
                      </h3>
                      <a
                        href={
                          order.travellingLink
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          margin: 0,
                          maxWidth: "60%",
                          wordWrap:
                            "break-word",
                          fontWeight: "500",
                        }}
                        id="address"
                      >
                        {
                          order.travellingLink
                        }
                      </a>
                    </div>
                  )} */}
            </div>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  );
};

export default HistoryAccordion;
