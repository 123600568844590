import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unknownRoute: [],
  error: null,
  computed: false,
  current: null,
  unknownLoading: false,
};

export const unknownSlice = createSlice({
  name: "unknownData",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.unknownLoading = true;
    },
    updateRoute: (state, action) => {
      state.current = action.payload;
    },
    updateUnknownSuccess: (state, action) => {
      state.unknownLoading = false;
      state.unknownRoute = action.payload;

      state.computed = true;
    },

    updateComputed: (state, action) => {
      state.computed = false;
    },

    hasError: (state, action) => {
      state.unknownLoading = false;
      state.computed = false;

      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoading,
  updateRoute,
  updateUnknownSuccess,
  updateComputed,
  hasError,
} = unknownSlice.actions;

export default unknownSlice.reducer;
