import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { collectionAgentType, unknownOrders } from "../../api";
import {
  Box,
  CircularProgress,
  Button,
  Card,
  Tabs,
  Tab,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
} from "@mui/material";
import PropTypes from "prop-types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "../../styles/group-card.scss";
import "../../styles/schedule.scss";
import "../../styles/user-card.scss";
import RouteTable from "./RouteTable";
import ScheduleOrderPanel from "./ScheduleOrderPanel";
import {
  startLoading,
  updateComputed,
  updateRoute,
  hasError,
  updateUnknownSuccess,
} from "../redux/slices/unknownState";
import { useSnackbar } from "notistack";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ScheduleCards = (props) => {
  const { isLoading, data, orderSummary, refreshDb, compute } = useSelector(
    (state) => state.scheduleData
  );

  const { enqueueSnackbar } = useSnackbar();
  const { computed, current } = useSelector((state) => state.unknownData);
  const dispatch = useDispatch();

  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };

  let count = [];

  {
    data &&
      data.length !== 0 &&
      data.map((orders, idx) => {
        Object.entries(orders).map(([key, value]) => {
          count.push({ route: value.Route, count: value.orderList.length });
        });
      });
  }

  let routeCount =
    count &&
    count.reduce((acc, cur, i) => {
      const item = i > 0 && acc.find(({ route }) => route === cur.route);
      if (item) item.count += cur.count;
      else acc.push({ route: cur.route, count: cur.count });
      return acc;
    }, []);

  routeCount = routeCount.sort((a, b) => (a.route > b.route ? 1 : -1));

  const totalOrders =
    data &&
    data
      .map((orders, index) =>
        Object.entries(orders).map(([key, value]) => value.orderList.length)
      )
      .flat()
      .reduce((a, b) => a + b, 0);

  const allID = [];
  data &&
    data.map((orders, index) =>
      Object.entries(orders).map(([key, value]) =>
        value.orderList.map((cell, index) => {
          allID.push(cell.order_no);
        })
      )
    );

  let unknown =
    data &&
    data.map((orders, index) =>
      Object.entries(orders).reduce((obj, [key, value]) => {
        if (value.Route === "Unknown") {
          obj[key] = value.orderList;
        }
        return obj;
      }, {})
    );

  const unknownArray = [];

  unknown &&
    unknown.map((orders, index) =>
      Object.entries(orders).map(([key, value]) =>
        value.map((cell, index) => {
          unknownArray.push({
            order_no: cell.order_no,
            latitude: cell.latitude,
            longitude: cell.longitude,
          });
        })
      )
    );

  // console.log("WHAT IS UNKNOWN", unknownArray);

  const [tabVal, setTabVal] = useState(0);
  const [collectionAgent, setCollectionAgent] = useState([]);

  const fetchCollection = async () => {
    let agents = await collectionAgentType();

    setCollectionAgent(agents);
  };

  const computeRoute = async () => {
    dispatch(startLoading());
    try {
      let unkno = await unknownOrders(unknownArray);
      // console.log("unknown", unkno);
      if (unkno.status === "Success") {
        dispatch(updateUnknownSuccess(unkno.answer));
      } else {
        dispatch(hasError());
        enqueueSnackbar("Error computing unknown routes.", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
      dispatch(hasError());
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    }
  };

  const handleChange = (event, newValue) => {
    setTabVal(newValue);
    dispatch(updateRoute(event.target.innerText));

    if (event.target.innerText.includes("UNKNOWN") && !computed) {
      computeRoute();
    }
  };

  useEffect(() => {
    fetchCollection();
  }, []);

  useEffect(() => {
    if (
      routeCount.length === 1 &&
      routeCount[0].route === "Unknown" &&
      !computed
    ) {
      computeRoute();
    }
  }, [routeCount]);

  useEffect(() => {
    if (current !== null && current.includes("UNKNOWN") && !computed) {
      computeRoute();
    }
  }, [computed]);

  return (
    <div style={{ margin: "1.5rem" }}>
      {!isLoading ? (
        <>
          <h3 align="center">
            Total Orders listed: {totalOrders || 0}
            <CopyToClipboard text={allID.map((id) => `${id}\n`).join("")}>
              <Button
                variant="contained"
                sx={{ marginLeft: "1vh" }}
                className="copy"
                startIcon={<ContentCopyIcon />}
              >
                Copy Order ID's
              </Button>
            </CopyToClipboard>
          </h3>

          {/* <RouteTable /> */}
          {orderSummary !== null && (
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper elevation={5} sx={{ padding: "10px" }}>
                <List sx={style} component="nav" aria-label="mailbox folders">
                  <ListItem button>
                    <ListItemText>
                      Total Orders to be Assigned:{" "}
                      <strong>{orderSummary.totalOrdersToBeAssigned}</strong>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem button divider>
                    <ListItemText>
                      Total Orders Assigned:{" "}
                      <strong>{orderSummary.totalOrdersAssigned}</strong>
                    </ListItemText>
                  </ListItem>
                  <ListItem button>
                    <ListItemText>
                      Total Orders Not Assigned:{" "}
                      <strong>{orderSummary.totalOrdersNotAssigned}</strong>
                    </ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </div>
          )}

          {data && data.length !== 0 && (
            <Card sx={{ margin: "20px" }}>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={tabVal}
                onChange={handleChange}
                sx={{
                  align: "center",
                  mx: "auto",
                  px: 2,
                  bgcolor: "background.neutral",
                }}
              >
                {routeCount.map((key) => {
                  return (
                    <Tab
                      disableRipple
                      key={key.route}
                      sx={{ mx: "auto", px: 2 }}
                      label={
                        <Stack spacing={1} direction="row" alignItems="center">
                          <div>
                            {key.route} [{key.count}]
                          </div>
                        </Stack>
                      }
                    />
                  );
                })}
              </Tabs>

              {routeCount.map((tabValue, i) => (
                <TabPanel value={tabVal} index={i}>
                  {data.map((orders, orderIndex) => {
                    return Object.entries(orders).map(([key, value]) => (
                      <ScheduleOrderPanel
                        collectionAgent={collectionAgent}
                        tabValue={tabValue}
                        orderIndex={orderIndex}
                        orders={orders}
                        orderKey={key}
                        orderValue={value}
                      />
                    ));
                  })}
                </TabPanel>
              ))}
            </Card>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            value={60}
            size={56}
            thickness={4}
            sx={{ position: "absolute", top: "50%", left: "50%" }}
            disableShrink
          />
        </Box>
      )}
    </div>
  );
};

export default ScheduleCards;
