import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Paper,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  TextField,
  Select,
  Button,
  Typography,
  MenuItem,
  Divider,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import moment from "moment";
import RescheduleOutput from "./RescheduleOutput";
import {
  startLoading,
  updateRescheduleSuccess,
} from "../redux/slices/rescheduleState";
import "../../styles/change-route.scss";
import { mismatchOrders, reschedOrders } from "../../api";
import { useDispatch } from "react-redux";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MismatchTable from "./MismatchTable";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Reschedule = () => {
  const times = ["1", "2", "3", "4"];
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [slot, setSlot] = useState(1);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [order, setOrder] = useState([]);
  const [timeSlot, setTimeSlot] = useState("10:00:00");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState(false);

  let orderList = [];

  const handleClose = () => {
    setOpen(false);
  };

  const handleMark = (event) => {
    setChecked(event.target.checked);
  };

  const handleMismatch = async () => {
    if (!fetched) {
      setLoader(true);
      try {
        let miss = await mismatchOrders();

        if (miss.status === "Success") {
          setLoader(false);
          setData(miss.data);
          setOpen(true);
          setFetched(true);
        } else {
          setOpen(false);
          setLoader(false);
          setFetched(false);
          enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
      } catch (error) {
        setLoader(false);
        setFetched(false);
        console.log(error);
        enqueueSnackbar("Something went wrong.", { variant: "error" });
      }
    } else {
      setOpen(true);
    }
  };

  const handleTime = (event) => {
    if (event.target.value == "1") {
      setTimeSlot("10:00:00");
    } else if (event.target.value == "2") {
      setTimeSlot("14:00:00");
    } else if (event.target.value == "3") {
      setTimeSlot("17:00:00");
    } else if (event.target.value == "4") {
      setTimeSlot("19:00:00");
    }
    setSlot(event.target.value);
  };

  const handleOrder = (event) => {
    // orderList.push(event.target.value);
    // console.log("ORDER LIST", orderList);
    // setOrder(event.target.value);
    let temp = event.target.value;
    // console.log(temp);
    orderList = temp.split(" ");
    setOrder(orderList);
    // console.log("array ", orderList);
  };

  const updateData = async () => {
    // let orderList = JSON.parse("[" + order + "]");
    // console.log("WHAT IS COMING IN LIST", orderList);

    let schedule = date.concat(" ", timeSlot);

    setLoading(true);
    dispatch(startLoading());
    const details = await reschedOrders(order, schedule, checked);
    dispatch(updateRescheduleSuccess(details.data));
    setFetched(false);

    setLoading(false);
  };

  return (
    <div>
      <div className="parent">
        <Paper sx={{ marginLeft: "10px", padding: "1rem" }}>
          <div className="stats-bar">
            <Button
              onClick={handleMismatch}
              sx={{ textDecoration: "none" }}
              variant="contained"
              startIcon={
                !loader ? (
                  <HistoryToggleOffIcon />
                ) : (
                  <CircularProgress color="inherit" size="1.2rem" />
                )
              }
            >
              <Typography sx={{ textDecoration: "none" }}>
                Show schedule mismatch Orders
              </Typography>
            </Button>

            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar color="success" sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Schedule mismatched orders
                  </Typography>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Total Orders: {data.length || 0}
                  </Typography>
                  <CopyToClipboard
                    text={
                      data &&
                      data.map((order) => `${order.order_no}\n`).join("")
                    }
                  >
                    <Button
                      variant="outlined"
                      color="inherit"
                      className="copy"
                      startIcon={<ContentCopyIcon />}
                    >
                      Copy All Order ID's
                    </Button>
                  </CopyToClipboard>
                </Toolbar>
              </AppBar>
              <MismatchTable tableData={data} />
            </Dialog>
          </div>
          <Paper elevation={5} className="search-bar">
            <div className="filterline1">
              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  //   onKeyPress={handleKeypress}
                  label="Select Date"
                  inputFormat="dd/MM/yyyy"
                  value={date}
                  onChange={(newValue) => {
                    const today = moment(newValue).format("YYYY-MM-DD");

                    setDate(today);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Divider
                className="divider"
                style={{ margin: 4 }}
                orientation="vertical"
              />

              <FormControl size={"small"} sx={{ minWidth: 100 }}>
                <InputLabel id="demo-simple-select-label">Slot</InputLabel>
                <Select
                  style={{ minWidth: 100, margin: 5 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={slot}
                  label="Slot"
                  onChange={handleTime}
                >
                  {times.map((time) => (
                    <MenuItem value={time}>{time}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />
              <div style={{ maxWidth: "15rem", margin: 5 }}>
                <TextField
                  size={"small"}
                  labelId="demo-simple-select-label"
                  style={{
                    // margin: "0rem 0.5rem 0rem 0.5rem",

                    width: "15rem",
                  }}
                  label="Order ID"
                  fullWidth
                  value={order.length ? order : ""}
                  onChange={handleOrder}
                  placeholder="Search"
                />
              </div>
              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <div className="update-button">
                <Button
                  className="searchButton"
                  style={{ margin: 5 }}
                  onClick={updateData}
                >
                  <IosShareIcon />
                </Button>
                <Typography style={{ fontSize: 11 }}>Update</Typography>
              </div>
            </div>
          </Paper>
          <FormControl
            sx={{ marginTop: "1vh", display: "flex", alignItems: "center" }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleMark}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Mark for Scheduling"
            />
          </FormControl>
        </Paper>
      </div>
      {order.length > 0 && (
        <Paper className="display-orders" elevation={5}>
          <strong>Orders Entered: {order.length}</strong>
          <br />
          <Grid
            container
            spacing={1}
            sx={{
              marginTop: "10px",
            }}
          >
            {order.map((o) => (
              <Box className="order-box" m={1} p={1}>
                {o}
              </Box>
            ))}
          </Grid>
        </Paper>
      )}

      <RescheduleOutput />
    </div>
  );
};

export default Reschedule;
