import axios from "axios";
import { STAGE_V2_API, HOST_API, SCHEDULE_API } from "./config";

// let token = localStorage.getItem("accessToken")
//   ? localStorage.getItem("accessToken")
//   : "";

const axiosInstance = axios.create({
  baseURL: STAGE_V2_API,
  timeout: 1000 * 60 * 5,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${
    //   localStorage.getItem("accessToken")
    //     ? localStorage.getItem("accessToken")
    //     : null
    // }`,
    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIsImlhdCI6MTY2ODQ0OTYyMCwiZXhwIjoxNjY4NTM2MDIwLCJ0eXBlIjoiYWNjZXNzIn0.3aWxuHWlal8kKW-p7jU62uWBX-JBynXK_50PhMfN3Vc`,
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

// axiosInstance.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
//   "accessToken"
// )}`;

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("accessToken");

    if (user) {
      config.headers.Authorization = `Bearer ${user}`;
    }

    return config;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

const axiosInstanceV2 = axios.create({
  baseURL: HOST_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json" },
  // headers: {
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${
  //     localStorage.getItem("accessToken")
  //       ? localStorage.getItem("accessToken")
  //       : null
  //   }`,
  // },
});

// axiosInstanceV2.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

axiosInstanceV2.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("accessToken");

    if (user) {
      config.headers.Authorization = `Bearer ${user}`;
    }

    return config;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

const axiosInstanceV3 = axios.create({
  baseURL: SCHEDULE_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json" },
});

axiosInstanceV3.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default {
  axiosInstance,
  axiosInstanceV2,
  axiosInstanceV3,
};
