import { CircularProgress, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postAccessToken } from "../api";
import { useAuthDispatch } from "../context/context";
import { useSnackbar } from "notistack";
// import { useHistory } from "react-router-dom";
import logoImg from "../logo/logo-black.png";
import "../styles/login.scss";
// import api from "../utils/api";
import { logout } from "./../context";
import { setSession } from "./jwt";

const Login = (props) => {
  const [username, setUsername] = useState("app-admin");
  const [password, setPassword] = useState("7u8i9o0p9");
  const [loginButtonProgress, setLoginButtonProgress] = useState(false);

  // const history = useHistory();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  var loginTimer;

  const runLogoutTimer = (timer) => {
    clearTimeout(loginTimer);

    loginTimer = setTimeout(() => {
      logout(dispatch);
      enqueueSnackbar("Token expired. Please login again", {
        variant: "warning",
      });
    }, timer);
  };

  const handleLogin = async (e) => {
    if (
      username === null ||
      username === "" ||
      password === null ||
      password === ""
    ) {
      // enqueueSnackbar("Please enter a valid username/password", {
      //   variant: "error",
      // });
      window.alert("Please enter a valid username/password");
      return;
    }

    setLoginButtonProgress(true);

    try {
      // const login = await authLogin(username, password);
      // console.log("AUTH LOGIN", login);

      const accessToken = await postAccessToken(username, password);

      if (accessToken.status === "Success") {
        // localStorage.removeItem("accessToken");

        localStorage.setItem("currentUser", JSON.stringify(accessToken.data));
        localStorage.setItem("accessToken", accessToken.data.access_token);

        // const { tokens, user } = login;

        setSession(dispatch, accessToken.data.access_token);

        // runLogoutTimer(accessToken.data.expires_in);

        dispatch({
          type: "LOGIN_SUCCESS",
          payload: accessToken.data,
        });
      } else {
        dispatch({ type: "LOGIN_ERROR", error: accessToken.message });
        throw accessToken.message;
      }
      setLoginButtonProgress(false);
      if (accessToken.data === null) return;
      localStorage.getItem("accessToken") !== "" &&
        navigate("/schedule", { replace: true });
      enqueueSnackbar("Logged In Successfully", { variant: "success" });
    } catch (error) {
      setLoginButtonProgress(false);
      enqueueSnackbar("ERROR: " + error, { variant: "error" });
      console.log(error);
    }
  };

  const handleUserName = (e) => {
    let strValue = e.target.value.trim();
    setUsername(strValue);
  };

  const handlePassword = (e) => {
    let strValue = e.target.value.trim();
    setPassword(strValue);
  };

  return (
    <Paper
      elevation={10}
      style={{
        backgroundColor: "#ecffee",
        borderRadius: "1rem",
        marginTop: "6vh",
      }}
      className="login-card"
    >
      <div className="lg-header-comp">
        <div className="image-div">
          <img id="logo" src={logoImg} alt="INFINITY BOX"></img>
        </div>
        <h3 id="lg-info">Login to the Scheduling Console</h3>
      </div>
      <div className="input-box">
        <TextField
          error={username === ""}
          id="username-box"
          label="Username"
          value={username}
          helperText={username === "" ? "Username is required." : ""}
          variant="outlined"
          onChange={handleUserName}
          className="username"
        />
        <TextField
          error={password === ""}
          id="password-box"
          label="Password"
          type="password"
          value={password}
          helperText={password === "" ? "Password is required." : ""}
          variant="outlined"
          onChange={handlePassword}
          className="password"
        />
      </div>
      <div className="button-box">
        <Button
          onClick={handleLogin}
          className="login"
          variant="contained"
          color="primary"
        >
          {loginButtonProgress ? (
            <CircularProgress
              style={{ margin: "0rem 0.95rem" }}
              color="secondary"
              size="1rem"
            />
          ) : (
            "Login"
          )}
        </Button>
        <Button
          className="reset"
          variant="contained"
          onClick={(e) => {
            setUsername("");
            setPassword("");
          }}
        >
          Reset
        </Button>
      </div>
    </Paper>
  );
};

export default Login;
