import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const MismatchTable = (props) => {
  return (
    <div>
      {props.tableData && props.tableData.length !== 0 && (
        <TableContainer sx={{ minWidth: 720, marginTop: "10px" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: "bolder" }}>Sl no.</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bolder" }}>
                    Order Number
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bolder" }}>
                    Scheduled At
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              {props.tableData.length > 0 &&
                props.tableData.map((orders, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {orders.order_no}{" "}
                          <CopyToClipboard text={orders.order_no}>
                            <IconButton aria-label="copy" size="small">
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </CopyToClipboard>
                        </TableCell>
                        <TableCell>{orders.scheduled_at}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default MismatchTable;
