import React from "react";
import "../../styles/schedule1.scss";
import ScheduleCards from "./ScheduleCards";
import StatsFilter from "./StatsFilter";

const Schedule = () => {
  return (
    <div>
      <div className="schedule-component">
        <div className="top-div">
          <div className="message-div">
            <StatsFilter />
          </div>
        </div>
      </div>
      <ScheduleCards />
    </div>
  );
};

export default Schedule;
