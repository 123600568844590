import React from "react";
import "../../styles/schedule.scss";
import HistoryList from "./HistoryList";
import HistoryFilter from "./HistoryFilter";

const History = () => {
  return (
    <div>
      <div className="schedule-component">
        <div className="top-div">
          <div className="message-div">
            <HistoryFilter />
          </div>
        </div>
      </div>
      <HistoryList />
    </div>
  );
};

export default History;
