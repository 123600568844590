import axios from "../axios";
import jwtDecode from "jwt-decode";
import { logout } from "./../context";

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp, dispatch) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log(timeLeft);
  expiredTimer = window.setTimeout(() => {
    console.log("expired token");
    logout(dispatch);
    window.alert("Token expired, please login again.");
    // You can do what ever you want here, like show a notification
  }, timeLeft);
};

export const setSession = (dispatch, accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    // axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    handleTokenExpired(exp, dispatch);
  } else {
    localStorage.removeItem("accessToken");
    // delete axiosInstance.defaults.headers.common.Authorization;
  }
};
